import type { ForwardedRef } from 'react';
import React from 'react';
import { isRelevantEvent } from 'ts/base/soy/SoyRenderer';
import { NavigationUtils } from 'ts/commons/NavigationUtils';

/** Props for TeamscaleLink. */
export type TeamscaleLinkProps = {
	to?: string;
	disabled?: boolean;
} & Omit<JSX.IntrinsicElements['a'], 'ref'>;

/**
 * An anchor element that links to another page in Teamscale and uses React Router under the hood to make the page
 * switch without a full page reload.
 */
export const TeamscaleLink = React.forwardRef<HTMLAnchorElement, TeamscaleLinkProps>(function TeamscaleLink(
	{ to, disabled, href, onClick, target, ...linkProps }: TeamscaleLinkProps,
	ref: ForwardedRef<HTMLAnchorElement>
): JSX.Element {
	if (href) {
		console.error('TeamscaleLink received a href prop. This will be ignored. Use `to` instead!');
	}
	if (!to) {
		return <a ref={ref} {...linkProps} />;
	}
	// Let browser handle "target=_blank" etc.
	const opensInNewTab = target && target !== '_self';
	return (
		<a
			ref={ref}
			href={to}
			target={target}
			onClick={event => {
				if (isRelevantEvent(event.nativeEvent) && !opensInNewTab) {
					event.preventDefault();
					onClick?.(event);
					NavigationUtils.updateLocation(to);
				}
			}}
			// <a> tags don't support disabled, but we can mimic it with CSS (see teamscale_root.less)
			className={disabled ? 'disabled' : ''}
			{...linkProps}
		/>
	);
});
